.bgLinearColor {
    background: linear-gradient(to bottom, #291946, #1B303F, #30346B) !important;
    min-height: 100vh;
}

.button-ming {
    background-color: #339DCF !important;
    color: #ffffff !important;
    padding: 14px 40px !important;
    border: 1px solid #339DCF !important;
    border-radius: 10px !important;
}

.button-search {
    background-color: rgba(0, 0, 0, 0) !important;
    color: #E5E5E5 !important;
    border: 1px solid !important;
    border-image: linear-gradient(to right, #29F19C, #02A1F9) 4 !important;
    padding: 14px 40px !important;
    border-radius: 10px !important;
}

.button-search:hover {
    border-image: linear-gradient(to right, #02A1F9, #29F19C) 4 !important;
}

.bottom-desc {
    background: url('../../../public/assets/bg_user.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -50px !important;
    z-index: 999 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.default-text-blur2 {
    color: #015eea !important;
}

.default-text-color {
    color: #E5E5E5 !important;
}

.default-text-blur {
    color: #339DCF !important;
}

.default-bg-blur {
    background: #339DCF !important;
}

.default-text-bababa {
    color: #BABABA !important;
}

.banner-nft {
    transition: all 0.3s ease;
    transform: perspective(650px) rotateX(0deg) rotateY(-20deg) scale(0.7);

}

.banner-nft:hover {
    transition: all 0.3s ease;
    transform: perspective(650px) rotateX(0deg) rotateY(0deg) scale(1.1);
}

.nft-items:hover .nft-image img {
    transform: scale(1.1);
}

.img-group .user-avatar img {
    position: relative;
    transition: all 0.5s ease;
}

.avatar.avatar-md-sm {
    height: 40px;
    width: 40px;
}

.btn-group {
    background: linear-gradient(to right, #015EEA, #00C0FA);
    height: 30px;
    line-height: 30px;
    min-width: 80px;
    border-radius: 15px !important;
}

.btn-group-disable {
    background: #ccc;
    height: 30px;
    line-height: 30px;
    color: #000;
    min-width: 80px;
    border-radius: 15px !important;
}

.bottom-style {
    box-shadow: rgb(136, 136, 136) 2px 16px 58px 21px;
    /* box-shadow:inset 0px 15px 10px -15px #E5E5E5; */
    height: 1px;
    box-shadow: 1px 32px 105px 7px #ffffff;
    border-top: 1px #E5E5E5 dashed;

}

.search-input {
    border: 1px solid #339DCF !important;
    background: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1.6;
    height: 40px;
    border-color: #EAECEF;
    background-color: transparent;
    border-radius: 20px;
    /* background-image: url(https://bin.bnbstatic.com/image/julia/nft/homepage/filled-inputsearch.png); */

    background-size: 100% 100%;
    /* width: 380px; */
    width: 270px;
    /* border: none !important; */
}

.search-input:hover {
    border: 1px solid #00C0FA !important;
}

.ant-tabs {
    color: #fff;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #339DCF;
    font-weight: bold;
}

.ant-tabs .ant-tabs-ink-bar {
    background: #339DCF;
}

.button-offer {
    background: linear-gradient(to right, #015EEA, #00C0FA);
    color: #ffffff !important;
    border: 0px solid #339DCF !important;
    border-radius: 20px !important;
    min-width: 167px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.button-common {
    min-width: 100px !important;
}

.buy-now {
    background: linear-gradient(to right, #29F19C, #02A1F9);
    color: #ffffff !important;
    border: 0px solid #339DCF !important;
    border-radius: 20px !important;
    min-width: 167px !important;
}

.min-button-offer {
    min-width: 107px !important;
}

.bg-blur {
    background-color: #339DCF !important;
    border: 1px solid #339DCF !important;
}

.category-style {
    display: flex !important;
}

.category-style-inline {
    border: 1px solid #fff;
    background: #999999;
    display: flex !important;
}

.font-weight-bold {
    font-weight: bold;
}

#table-nft .table-striped>tbody>tr:nth-of-type(odd) {
    background-color: rgba(51, 157, 201, 0.2);
}

#table-nft .table-striped>tbody>tr:nth-of-type(odd)>* {
    color: #E5E5E5 !important;
}

#table-nft .table-striped>tbody>tr:nth-of-type(even) {
    color: #E5E5E5 !important;
}

#table-nft .table-striped>tbody>tr:nth-of-type(even)>* {
    color: #E5E5E5 !important;
}

.gap-border {
    border: 2px #C9C9C9 solid;
}

.pup-outer {
    -webkit-box-shadow: inset rgba(255, 255, 255, 0.8) 0px 0px 50px 0px !important;
    box-shadow: inset rgba(255, 255, 255, 0.8) 0px 0px 20px 0px !important;
}

.pup-outer2 {
    -webkit-box-shadow: inset rgba(255, 255, 255, 0.8) 0px 0px 50px 0px !important;
    box-shadow: inset rgba(255, 255, 255, 0.8) 0px 0px 20px 0px !important;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-header,
.btn-close {
    color: #E5E5E5 !important;
}

.modal-content {
    position: relative;
    background-color: rgba(0, 0, 0, 0.8) !important;
    -webkit-background-clip: padding-box !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(51, 157, 207, 0.8) !important;
    outline: 0 !important;
    -webkit-box-shadow: rgba(51, 157, 207, 0.8) 0px 0px 50px 0px !important;
    box-shadow: rgba(51, 157, 207, 0.8) 0px 0px 20px 0px !important;
    border-radius: 8px !important;
    overflow: hidden;

}

.top-box-shadow:hover {
    border: 2px solid #02A1F9;
    box-shadow: 0 0 32px 0 rgba(2, 161, 249, .5);
}

.top-box-shadow-select {
    border: 2px solid #02A1F9;
    box-shadow: 0 0 32px 0 rgba(2, 161, 249, .5);
}

#dropdown-basic {
    background: linear-gradient(to right, #015EEA, #00C0FA);
    min-width: 80px;
    border-radius: 15px !important;
}



.dropdown-menu a {
    color: #ffffff !important;


}

.dropdown-menu a:hover {
    color: #015EEA !important;
}

.box-border {
    position: relative;
    border: 2px solid;
    border-image: linear-gradient(45deg, #015EEA, #00C0FA) 1;
    clip-path: inset(0 round 4px);
}

.input-style {
    background-color: rgba(51, 157, 201, 0.3) !important;
    min-height: 60px !important;
}

.select-style {
    background-color: rgba(51, 157, 201, 0.3) !important;
    min-height: 60px !important;
    background-image: url('../../../public/assets/arrowdown.png') !important;
}

.text-area-style {
    background-color: rgba(51, 157, 201, 0.3) !important;
    min-height: 120px;
    min-width: 200px;
    max-width: 300px;
}

.wallet-row {
    background-color: rgba(51, 157, 201, 0.3) !important;
}

.wallet-row:hover {
    border: 2px solid #02A1F9;
    box-shadow: 0 0 32px 0 rgba(2, 161, 249, .5);
}

#example-fade-text {
    position: relative;
    z-index: 1050;
    top: 30%;
}

.dropdown-btn {
    position: relative;
    z-index: 1045;
    overflow: initial !important;
}

.dropdown-menu {
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: rgb(136, 136, 136) 0px 0px 50px 10px inset;
}

.accordion,
.accordion-item,
.accordion-header,
.accordion-button {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px !important;
}

.accordion-body {
    padding: 0;
}

.ant-picker-dropdown {
    z-index: 99999;
}

.ant-message-top {
    z-index: 99999;
}

.h2Style {
    min-width: 90px !important;
    text-align: center;
}


::-webkit-scrollbar {
    width: 0.75rem !important;
    height: 0.75rem !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0) !important;
    border-radius: 0.625rem !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 0.625rem !important;
    box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0) !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.ant-pagination-item,
.ant-pagination-next,
.ant-pagination-prev {
    background-color: #ffffff !important;
}

.ant-pagination-item-active {
    background-color: #1677ff !important;
    color: #ffffff !important;
}

.modal-body .ant-pagination-item-active {
    background-color: rgb(250, 213, 81) !important;
    border-color: rgb(250, 213, 81) !important;
    color: #000 !important;
}

.ant-pagination-item-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.ant-pagination-item-ellipsis {
    color: #ffffff !important;
}

.ant-select-selector {
    background: rgba(255, 255, 255, 0) !important;
    height: 45px !important;
    line-height: 45px !important;
    border-radius: 1px !important;
    color: #E5E5E5 !important;
}

.ant-select-selection-item {
    height: 45px !important;
    line-height: 45px !important;
}

.pledgd-button {
    width: 129px;
    height: 56px;
    min-width: 129px !important;
}

.tdOuter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tdouter-left {
    width: 130px;
}

.tdouter-right {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 5px;
    background: #322554;
    width: 100%;
    border-radius: 20px;
}

.pledgd-button1 {
    width: 100px;
    height: 40px;
    min-width: 100px !important;
}

.xxTdbiOuter {
    width: 210px;
    text-align: left;

}

.xxbiOuter {
    width: 160px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 20px 0px;
}

.xxbi {
    width: 55px;
    height: 55px;
    border-radius: 50%;

}

/* .tdouter-right div{
	display:flex;
	justifyContent: space-around;
	alignItems: center; 
	width: 33%;
} */
.banner {
    padding: 32px;
    background: url("../../images/farms_bg.png") center 0px / auto 100% no-repeat, linear-gradient(139.73deg, rgb(126, 189, 255) 0%, rgb(151, 129, 255) 100%);
    border-radius: 30px 0 0 0;
    color: #fff;
}

.banner h1 {
    font-weight: bold;
}

/* 手机设备的样式（宽度最大为767px） */
@media screen and (max-width: 767px) {
    .pledgelist th {
        min-width: 60px;
        text-align: center;
    }

    .pledgelist td {
        min-width: 60px;
        text-align: center;
    }

    .xxTdbiOuter {
        width: 110px;
        text-align: left;
    }

    .xxbiOuter {
        width: 90px;
    }

    .xxbiOuter img {
        display: none;
    }

    .xxbi {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .tdouter-left {
        display: none;
    }

    .tdouter-right {
        display: block;

    }

    .pendingFit button {}


    .modal-content {
        width: 80%;

    }

    .pendingFit {
        display: block;
    }

    .pendingFitTxt {
        width: 100%;
        text-align: left;
    }

    .userAllstake {
        width: 50%;
        float: left;
        text-align: left;
    }

    .userBal {
        width: 50%;
        float: left;
        text-align: right;
        font-size: 10px;
    }
}



.table_box {
    filter: drop-shadow(rgba(25, 19, 38, 0.15) 0px 1px 4px);
    background: rgb(24, 25, 43);
    box-shadow: rgba(22, 39, 117, 0.09) 0px 6px 16px 0px;
    border-radius: 16px;
    overflow: hidden;
}

.table_ctn {
    border-collapse: collapse;
    font-size: 14px;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: rgb(250, 251, 251);

}

.tr_ctn {
    border-bottom: 1px solid rgb(29, 34, 55);
}

.td_ctn {
    display: flex;
    align-items: center;
    padding: 24px 8px 24px 0px;
}

.token_logo {
    margin-left: 40px;
    margin-right: 5px;
    width: 40px;
    border-radius: 50%;
}

.down_icon {
    transition: transform 0.3s;
    fill: rgb(103, 108, 155);
}

.tr_open .down_icon {
    transform: rotate(180deg);
}

@keyframes bDoNKh {
    0% {
        max-height: 0px;
    }

    100% {
        max-height: 500px;
    }
}

.td_collapse {
    animation: 300ms linear 0s 1 normal forwards running bDoNKh;
    overflow: hidden;
    background: linear-gradient(rgb(22, 22, 37), rgb(41, 32, 62));
    /* display: flex;
    align-items: center; */
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
}

.td_collapse_inner {
    display: flex;
    align-items: center;
}

.link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 5px;
    line-height: 1;
}

.link a {
    color: rgb(103, 108, 155);
    font-size: 14px;
}

.link svg {
    fill: rgb(103, 108, 155);
    margin-left: 4px;
    flex-shrink: 0;
}

.badge2 {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 16px;
    display: inline-flex;
    font-weight: 400;
    white-space: nowrap;
    height: 28px;
    padding: 0px 8px;
    font-size: 14px;
    color: rgb(255, 255, 255);
    background: linear-gradient(rgb(22, 22, 37), rgb(41, 32, 62));
    border: 2px solid rgb(255, 255, 255);
}

.badge2 svg {
    fill: #fff;
    width: 14px;
}

.collapse_right {
    display: flex;
    align-items: center;
    flex: 1;
}

.border_box {
    flex: 1;
    padding: 16px;
    border: 2px solid rgb(29, 34, 55);
    border-radius: 16px;
}

.draw {
    width: 50%;
}

.receive_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.border_title {
    color: rgb(255, 255, 255);
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    font-size: 12px;
}

@media screen and (min-width: 1080px) {
    .td_ctn {
        padding-right: 32px;
    }

    .td_collapse {
        padding: 24px 20px;
    }
}

.mobile_ctn {
    display: none;
}

@media screen and (max-width: 767px) {
    .td_ctn {
        padding-right: 16px;
    }

    .td_collapse {
        padding: 24px 20px;
    }

    .token_logo {
        margin-left: 16px;
    }

    .mobile_ctn {
        display: block;
    }

    .pc_ctn {
        display: none;
    }

    @keyframes bDoNKh {
        0% {
            max-height: 0px;
        }

        100% {
            max-height: 2000px;
        }
    }

    .td_collapse {
        display: block;
        animation: 300ms linear 0s 1 normal forwards running bDoNKh;
        padding: 16px;
    }

    .collapse_right {
        display: block;
    }

    .mt20 {
        margin-bottom: 20px;
    }

    .line_ctn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .lp_modal .lp_input {
        width: 80px;
    }
}

.lp_modal .modal-content {
    width: auto;
    margin: auto;
    box-shadow: none !important;
    background: #18182c !important;
    border: none !important;
    border-radius: 20px !important;
}

.lp_modal .modal-header {
    padding: 12px 24px;
}

.lp_modal .close_icon {
    fill: rgb(103, 108, 155);
}

.lp_modal .stake_title {
    font-size: 20px;
    font-weight: 600;
    line-height: 48px;
}

.lp_modal .modal-body {
    padding: 24px;
}

.lp_modal .modal-content .pup-outer {
    box-shadow: none !important;
}

.lp_modal .modal-header {
    border-bottom: 1px solid rgb(29, 34, 55) !important;
}

.lp_modal .modal-footer {
    border-top: 1px solid rgb(29, 34, 55) !important;
}

.lp_modal .lp_input {
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
}

.lp_modal .lp_input:focus:not(:disabled) {
    box-shadow: none;
}

.lp_modal .lp_form {
    display: flex;
    flex-direction: column;
    background: rgb(29, 34, 55);
    border-radius: 16px;
    box-shadow: rgba(74, 74, 104, 0.1) 0px 2px 2px -1px inset;
    color: rgb(250, 251, 251);
    padding: 8px 16px 8px 16px;
    width: 100%;
}

.lp_input {
    color: rgb(250, 251, 251);
    display: block;
    font-size: 16px;
    height: 40px;
}

.lp_maxbtn {
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 32px !important;
    padding: 0 !important;
    width: 80px !important;
    margin-right: 8px;
}

.hdmGsl {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.kldLoa {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.iyQkTx {
    color: rgb(250, 251, 251);
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    white-space: nowrap;
}

.cgvgt {
    color: rgb(250, 251, 251);
    font-weight: 400;
    line-height: 1.5;
    font-size: 14px;
}

.lp_modal .lp_form_amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin-top: 24px;
    margin-bottom: 24px;
}

.big_font {
    font-size: 20px;
    font-weight: bold;
}

.base_btnbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.base_btnbox .base_item {
    flex: 1;
}

.base_btn {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 32px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 48px;
    padding: 0px 24px;
    background-color: transparent;
    border: 2px solid rgb(250, 213, 81);
    box-shadow: none;
    color: rgb(250, 213, 81);
    width: 100%;
}

.base_btn:hover:not(:disabled) {
    opacity: 0.65;
}

.base_btn2 {
    -webkit-box-align: center;
    align-items: center;
    border: 0px;
    border-radius: 32px;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.03em;
    line-height: 1;
    opacity: 1;
    outline: 0px;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    height: 48px;
    padding: 0px 24px;
    background-color: rgb(250, 213, 81);
    box-shadow: none;
    color: rgb(9, 10, 25);
    width: 100%;
}

.base_btn2:disabled {
    background-color: rgb(136, 136, 136);
    border-color: rgb(136, 136, 136);
    box-shadow: none;
    color: rgb(238, 238, 238);
    cursor: not-allowed;
}

.base_btn2:hover:not(:disabled) {
    opacity: 0.65;
}

.nft_scroll {
    width: 350px;
    max-height: 260px;
    overflow-y: auto;
    text-align: center;
}

.nft_scroll::-webkit-scrollbar {
    width: 0 !important;
}

.nft_box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nft_img {
    width: 100%;
    max-width: 100px;
}

.modal-body .nft_page {
    text-align: center;
    padding: 20px 0;
}

.nft_load {
    background-color: rgb(250, 213, 81);
}

.nft_load .ant-spin-dot-item {
    background-color: #fff !important;
}

.pl_card {
    height: 129px;
    background-image: url("../../images/card.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pl_card .card_label {
    font-size: 15px;
    color: #FEFEFE;
    margin-bottom: 15px;
}

.pl_card .card_num {
    font-size: 21px;
    color: #0092F2;
}

.income_card {
    height: 307px;
    background-image: url("../../images/card2.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    padding: 0 24px;
}

.income_card .card_icon {
    width: 18px;
    height: 21px;
    margin-left: 5px;
}

.income_card .card_title {
    height: 56px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #FEFEFE;
    border-bottom: 2px solid rgba(241, 241, 241, 0.14);
}

.stake_card {
    height: 374px;
    background-image: url("../../images/card3.png");
}

.income_card2 {
    height: 88px;
    background-image: url("../../images/card4.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.income_card2 .card_label {
    font-size: 17px;
    color: #fff;
}

.income_card2 .card_num {
    font-size: 21px;
    color: #00BBF9;
}

.input_box {
    position: relative;
    width: 276px;
    height: 38px;
    background: #252D45;
    border: 1px solid #0094F3;
    border-radius: 8px;
}

.ocean_logo {
    width: 45px;
    vertical-align: top;
    margin-right: 15px;
}

.input_label {
    margin-bottom: 10px;
    font-size: 14px;
}

.input_box input {
    border: none;
    height: 100%;
}

.input_box .all {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -11px;
    width: 45px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background-image: url("../../images/all.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    font-size: 13px;
    font-weight: bold;
    color: #202841;
    cursor: pointer;
    z-index: 1;
}

/* 去掉右侧上下箭头 */
.input_box input::-webkit-outer-spin-button,
.input_box input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input_box input[type="number"] {
    -moz-appearance: textfield;
}

.tips1 {
    font-size: 17px;
    color: #339DCF;
}

.tips2 {
    font-size: 15px;
    color: #FFFFFF;
}
@media screen and (max-width: 767px) {
    .pl_card {
        height: 100px;
    }
    .pl_card .card_label {
        margin-bottom: 10px;
    }
    .income_card {
        height: auto;
        padding-bottom: 20px;
    }
    .input_box {
        width: 100%;
    }
    .income_card2 {
        height: 70px;
    }
    .income_card2 .card_label {
        font-size: 14px;
    }

    .income_card2 .card_num {
        font-size: 18px;
    }
}