body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#collasible-nav-dropdown{ 
  color: #fff;
}
.ant-input{
  background-color: rgba(0,0,0,0);
  color:#ffffff
}

.ant-input::-webkit-input-placeholder { /* WebKit browsers */
  color: #DFDFDF;
  font-size: 16px;
}

.ant-input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #DFDFDF;
  font-size: 16px;
}

.ant-input:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #DFDFDF;
  font-size: 16px;
}
.bannerText1{ 
    font-size: 1.5em;  
    background-image: linear-gradient(135deg,#29F19C,#02A1F9);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    -moz-box-decoration-break: clone;
    color: transparent;
    word-break: keep-all; 
}
button{
  border:none
}
 